import React, { useState, useEffect, useRef, useCallback } from "react";

import { MapContainer, TileLayer, GeoJSON } from "react-leaflet";
import type { LatLngExpression, Map } from "leaflet";
import { area, convex, center, distance, point } from "@turf/turf";
import rewind from "@mapbox/geojson-rewind";

// Import componentsProjectTypesl";
import WelcomeModal from "./Modals/WelcomeModal";
import AOIModal from "./Modals/AOIModal";
import EditFeature from "./Map/EditFeatures";
import ProjectDetails from "./Modals/ProjectDetails";
import AdditionalInfoModal from "./Modals/AdditionalInfoModal";
import L from "leaflet";

import "bootstrap/dist/css/bootstrap.min.css";
import "leaflet/dist/leaflet.css";
import "./Styles/global.css";
import OutputModal from "./Modals/OutputModal";
import ErrorModal from "./Modals/ErrorModal";

// Types
import { FormParams, Message } from "./Types/Types";
import axios from "axios";

interface Response {
  token: string;
  message: string;
  pass: boolean;
  total: number;
  host: string;
}

const position: LatLngExpression = [39.8283, -98.5795];
const kalo = point([-92.4013, 42.55834]);

const EstimateForm = ({
  lidar_spec,
  imagery_accuracies,
  imagery_class,
  host,
  token,
}) => {
  const [map, setMap] = useState<Map | null>(null);
  const [kmlAOI, setKmlAOI] = useState<any | null>(null);
  const [features, setFeatures] = useState<any | null>(null);
  const [aoi, setAOI] = useState<any | null>(null);

  // form management
  const [submitted, setSubmitted] = useState(false);
  const [message, setMessage] = useState<Message | null>(null);

  const [clientName, setClientName] = useState("");
  const [projectName, setProjectName] = useState("");
  const [step, setStep] = useState("welcome");
  const [sqMiArea, setSqMiArea] = useState<number | null>(null);
  const [dist, setdist] = useState<number | null>(null);
  const [response, setResponse] = useState<Response | null>(null);

  const [formObj, setFormObj] = useState<FormParams>({
    client_name: "",
    project_name: "",
    phone_no: "",
    email: "",
    features: null,
    aoi: null,
    sched_restrict_enabled: true,
    sched_restrict_label: "Collect within 2 weeks, normal delivery",
    other_quote_valid_30_days: true,
    other_work_limitations: "ASI must perform this work"
  });

  console.log({ formObj, features, aoi });

  // Updates the Form Object
  const update = (property: string, value: boolean | string | number) => {
    let obj = { ...formObj };
    obj[property] = value;

    // console.error("UPDATE", { property, value, obj });
    setFormObj(obj);
  };

  const submit = () => {
    console.log("Submit", formObj);

    if (!formObj.features) {
      setMessage({
        type: "Draw",
        message: "AOI Boundary is required and missing from submission",
      });
      return false;
    }

    if (!formObj.aoi) {
      setMessage({
        type: "Draw",
        message: "AOI Boundary is required and missing from submission",
      });
      return false;
    }

    if (!formObj.client_name) {
      setMessage({
        type: "QuoteDetails",
        message: "Client Name is required and missing from submission",
      });
      return false;
    }
    if (!formObj.project_name) {
      setMessage({
        type: "QuoteDetails",
        message: "Project Name is required and missing from submission",
      });
      return false;
    }
    if (!formObj.phone_no) {
      setMessage({
        type: "QuoteDetails",
        message: "Phone Number is required and missing from submission",
      });
      return false;
    }
    if (!formObj.email) {
      setMessage({
        type: "QuoteDetails",
        message: "Email is required and missing from submission",
      });
      return false;
    }

    if (!formObj.lidar_co_ca_cl_enabled && !formObj.image_co_or_enabled) {
      setMessage({
        type: "ProjectDetails",
        message:
          'Must select an option from "LiDAR Collection, Calibration, Automated Classification" or "Imagery Collection, orthorectified" to submit estimate',
      });
      return false;
    }

    if (
      formObj.lidar_co_ca_cl_enabled &&
      (!formObj.lidar_co_ca_cl_label || !formObj.lidar_co_ca_cl_accuracy_label)
    ) {
      setMessage({
        type: "ProjectDetails",
        message:
          'Density and Accuracy must be set under "LiDAR Collection, Calibration, Automated Classification". ',
      });
      return false;
    }

    if (
      formObj.image_co_or_enabled &&
      (!formObj.image_co_or_enabled ||
        !formObj.image_co_or_label ||
        !formObj.image_co_or_accuracy_label ||
        !formObj.image_co_or_grade ||
        !formObj.image_co_or_sun_angle)
    ) {
      setMessage({
        type: "ProjectDetails",
        message:
          'GSD, Accuracy, Imagery Grade, and Sun Angle must be set under "Imagery Collection, orthorectified". ',
      });
      return false;
    }

    setSubmitted(true);

    if (formObj.token) {
      axios
        .patch(`/estimate/${formObj.token}`, {
          estimate: formObj,
          authenticity_token: token,
        })
        .then(({ data }) => {
          console.log(data);

          if (data.pass && parseFloat(data.total) > 0) {
            setStep("result");
            setResponse(data);

            // open the report in a new window
            // window.open(`/estimate/${data.token}`);
          }

          setSubmitted(false);
        });
    } else {
      axios
        .post(`/estimate`, {
          estimate: formObj,
          authenticity_token: token,
        })
        .then(({ data }) => {
          console.log(data);

          if (data.pass && parseFloat(data.total) > 0) {
            // update the token in the form obj to update the estimate raterh than creating a new one
            update("token", data.token);

            setStep("result");
            setResponse(data);

            // open the report in a new window
            // window.open(`/estimate/${data.token}`);
          }

          setSubmitted(false);
        });
    }
  };

  const hashString = (str) => {
    var hash = 0,
      i,
      chr;
    for (i = 0; i < Math.min(str.length, 255); i++) {
      chr = str.charCodeAt(i);
      hash = (hash << 5) - hash + chr;
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
  };

  // useEffect(() => {
  //   if (clientName || projectName) {
  //     const obj = { ...formObj };
  //     obj.client_name = clientName;
  //     obj.project_name = projectName;
  //     obj.boundary = JSON.stringify(aoi);
  //     setFormObj(obj);
  //   }
  // }, [clientName, projectName, aoi]);

  useEffect(() => {
    if (step === "draw") {
      setFeatures(null);
      setAOI(null);
    }
  }, [step]);

  useEffect(() => {
    if (features) {
      console.log({ features });

      update("features", JSON.stringify(features));
      setAOI(convex(features));
      setStep("project_details");
    } else {
      setAOI(null);
      update("features", null);
    }
  }, [features]);

  useEffect(() => {
    if (aoi) {
      setSqMiArea(area(aoi) * 3.86102e-7);
      var centerLatLng = center(aoi);
      var distanceToCenter = distance(kalo, centerLatLng, { units: "miles" });
      setdist(distanceToCenter);
      update("aoi", JSON.stringify(aoi));
    } else {
      setSqMiArea(null);
      setdist(null);
      update("aoi", null);
    }
  }, [aoi]);

  return (
    <div>
      {message && (
        <ErrorModal
          message={message}
          setMessage={setMessage}
          setStep={setStep}
        />
      )}

      {step === "welcome" && (
        <WelcomeModal
          setStep={setStep}
          clientName={clientName}
          setClientName={setClientName}
          projectName={projectName}
          setProjectName={setProjectName}
        />
      )}

      {step === "aoi" && <AOIModal setStep={setStep} setKmlAOI={setKmlAOI} />}

      {step === "project_details" && convex && sqMiArea && dist && (
        <ProjectDetails
          setStep={setStep}
          area={sqMiArea}
          dist={dist}
          aoi={aoi}
          token={token}
          // setResponse={setResponse}
          formObj={formObj}
          setFormObj={setFormObj}
          update={update}
          submit={submit}
          lidar_spec={lidar_spec}
          imagery_accuracies={imagery_accuracies}
          imagery_class={imagery_class}
        />
      )}

      {step === "additional_info" && (
        <AdditionalInfoModal
          update={update}
          formObj={formObj}
          submit={submit}
          // message={message}
          setStep={setStep}
        />
      )}

      {step == "result" && response && (
        <OutputModal
          response={response}
          setStep={setStep}
          host={host}
          formObj={formObj}
        />
      )}

      <MapContainer center={position} zoom={5} ref={setMap} id="map-container">
        <TileLayer
          maxZoom={16}
          minZoom={4}
          attribution='Tiles courtesy of the <a href="https://usgs.gov/">U.S. Geological Survey</a>'
          url="https://basemap.nationalmap.gov/arcgis/rest/services/USGSImageryTopo/MapServer/tile/{z}/{y}/{x}"
        />

        {step != "draw" && aoi && features && (
          <GeoJSON
            key={hashString(JSON.stringify(aoi))}
            data={aoi}
            style={{
              fillColor: "#00FFFB",
              fillOpacity: 0,
              color: "#B31F1F",
              weight: 4,
              dashArray: "20, 20",
              dashOffset: "20",
            }}
          />
        )}

        <EditFeature
          step={step}
          setKmlAOI={setKmlAOI}
          kmlAOI={kmlAOI}
          setMapFeatures={setFeatures}
        />
      </MapContainer>
    </div>
  );
};

export default EstimateForm;
